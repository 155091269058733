import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-b933aab2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 1
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  style: {
    "flex": "1"
  }
};
const _hoisted_4 = {
  class: "font-bold"
};
const _hoisted_5 = {
  class: "label"
};
const _hoisted_6 = {
  style: {
    "flex": "1",
    "text-align": "right"
  }
};
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  key: 0
};
const _hoisted_9 = {
  key: 1
};
const _hoisted_10 = {
  key: 0,
  class: "warning"
};
const _hoisted_11 = {
  key: 1
};
const _hoisted_12 = {
  key: 2,
  class: "warning"
};
const _hoisted_13 = {
  key: 3,
  class: "warning"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_skeleton = _resolveComponent("van-skeleton");

  const _component_van_empty = _resolveComponent("van-empty");

  return !_ctx.isComplete ? (_openBlock(), _createBlock(_component_van_skeleton, {
    key: 0,
    title: "",
    style: {
      "margin-top": "50px"
    },
    row: 5
  })) : (_openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.list.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: "cell-group",
      key: index
    }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(item.type == 1 ? '充值' : '提现'), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(item.create_time), 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", null, [item.type == 2 ? (_openBlock(), _createElementBlock("text", _hoisted_7, "-")) : _createCommentVNode("", true), _createElementVNode("text", null, _toDisplayString(item.amount), 1)]), item.type == 1 ? (_openBlock(), _createElementBlock("div", _hoisted_8, "充值成功")) : item.type == 2 ? (_openBlock(), _createElementBlock("div", _hoisted_9, [item.status == 0 ? (_openBlock(), _createElementBlock("text", _hoisted_10, " 微信支付系统处理中，预计" + _toDisplayString(item.expected_date) + "前到账 ", 1)) : item.status == 1 ? (_openBlock(), _createElementBlock("text", _hoisted_11, "提现成功")) : item.status == 2 ? (_openBlock(), _createElementBlock("text", _hoisted_12, "提现失败")) : item.status == 3 ? (_openBlock(), _createElementBlock("text", _hoisted_13, " 网络延迟，请重新发起提现 ")) : _createCommentVNode("", true)])) : _createCommentVNode("", true)])]);
  }), 128))])) : (_openBlock(), _createBlock(_component_van_empty, {
    key: 1,
    description: "暂无内容",
    style: {
      "margin-top": "200px"
    }
  }))]));
}